/* Variable for colors */
$white: #fff;
$black: #000;
$main-black: #21202d;
$stone-green: #00b487;
$liat-red: #f55050;
$dodger-blue: #4e90d4;
$tundora: #4a4a4a;
$heather: #c4d1d8;
$mercury: #e6e6e6;
$mineShaft: #333;
$concrete: #f2f2f2;
$wild-sand: #f5f5f5;
$abbey: #505059;
$gulf-stream: #88b7ab;
$gallery: #efefef;
$light-gallery: #ececec;
$dove-gray: #707070;
$silver-chalice: #aeaeae;
$torch-red: #ff0000;
$alto: #dfdfdf;
$silver-chalice: #9f9f9f;
$dusty-gray: #949494;

/* Storybook colors */
$oslo-gray: #f6f7fb;
$geyser: #dbecf8;
$mischka: #dee1e6;
$silver: #c1c6c9;
$lightSilver: #c4c4c4;
$lighterSilver: #bababa;
$hit-gray: #99a0a6;
$gray: #e5e5e5;
$alabaster: #f9f9f9;

/* Components color */
$side-bar-menu: #b2b2b2;
$side-bar-link: #fffdfd;
$table-strip-bg: $alabaster;
$input-border: $heather;

$select-menu-color: $tundora;
$select-menu-hover-item: $mischka;
$master-class-text-color: $mineShaft;

$error-color: #f44336;

$disabled-radio: $dusty-gray;
$label-error: #ff8a00;
$label-success: $stone-green;
$group-menu-item: $lighterSilver;
$dropdown-disable: #ddd;
