.table {
  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__cell {
    padding: 5px;
  }
}

.fixed-header-table {
  width: 600px;
  table-layout: fixed;

  thead {
    background: $black;
    color: $white;

    tr {
      display: block;
    }
  }

  th,
  td {
    width: 200px;
  }

  tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 400px;
  }

  &--no-result {
    tbody {
      height: auto;
    }
  }
}
