.modal-container,
.modal,
.modal-header,
.modal-footer {
  @extend %display-flex;
  align-items: center;
}

.modal-container,
.modal-backdrop {
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

.modal-container {
  position: fixed;
  justify-content: center;
  z-index: $z-index-xs;
  overflow: hidden;

  .modal {
    width: 70%;
    background-color: $white;
    position: absolute;
    z-index: $z-index-base;
    max-height: 100%;
  }

  .modal-dialog {
    padding: 30px 40px;
    width: 100%;
    position: relative;

    .loading-indicator {
      position: absolute;
      left: 0;
      top: 0;
      background-color: $white;
      z-index: $z-index-max + 1;
      height: 100%;
    }
  }

  .modal-header {
    align-items: center;
    justify-content: flex-end;
  }

  .modal-body,
  .modal-body__input-title,
  .modal-body .label-group {
    @extend %display-flex;
  }

  .modal-body {
    padding-bottom: 15px;
    text-align: center;

    .title {
      font-size: $font-size-sm;
      text-transform: uppercase;
      margin-top: 5px;
      text-align: left;
    }

    .title,
    .label-group__text {
      @extend .font-secondary;
    }

    h5 {
      @extend .font-light;
      font-size: $font-size-sm;
      margin-top: 0;
    }

    &__input-title {
      @extend .font-trade;
      font-size: $font-size-xs;
      text-transform: uppercase;
    }

    .label-group {
      justify-content: space-between;
      direction: ltr;
      margin: 0 20px 10px 10px;
      width: 200px;

      &:hover {
        &,
        i {
          color: $liat-red;
        }
      }
    }

    .modal-single-column {
      padding-right: 50px;
      flex-direction: column;
    }

    .modal-columns {
      width: 400px;
      margin: 40px 0 0 50px;
    }

    &__list-item {
      direction: rtl;
      overflow-y: auto;
      height: 300px;
    }

    .label-group__text {
      text-align: left;
      font-size: $font-size-tiny;
      margin: 0;
    }

    .form__auto-complete__text,
    .icon__auto-complete-close {
      color: $black;
    }
  }

  .modal-footer {
    justify-content: flex-end;
    background-color: $white;
    width: 100%;

    .btn-submit {
      padding: 0;
    }

    .spinner-layer {
      border-color: $white;
    }

    .btn-success--large {
      margin-right: 30px;
    }
  }

  .modal-backdrop {
    position: absolute;
    background-color: $black;
    opacity: 0.5;
  }

  .material-icons {
    color: $heather;
    cursor: pointer;
  }
}
