.form__label {
  @extend .font-light;
  font-size: $font-size-xs;

  &--error,
  &--success {
    @extend .font-bold;
    font-size: $font-size-sm;
  }

  &--error {
    color: $label-error;
  }

  &--success {
    color: $label-success;
  }
}

.label-error {
  @extend .font-light;
  font-size: $font-size-xs;
  color: $error-color;
}

.form__list-item {
  @extend .font-light;
  font-size: $font-size-tiny;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
