%display-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}
