.popup-container,
.popup,
.popup-header,
.popup-footer {
  @extend %display-flex;
  align-items: center;
}

.popup-container {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  justify-content: center;
  z-index: $z-index-xs;
  overflow: hidden;

  .popup {
    width: 400px;
    background-color: $white;
    border-radius: $border-radius-md;
    position: absolute;
    z-index: $z-index-base;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &--lg {
      width: 596px;
    }

    &--flat {
      border-radius: 0;

      .popup-header {
        border-bottom: none;

        .title {
          @extend .font-secondary;
          width: 400px;
          margin: 30px auto 0;
          text-transform: uppercase;
          font-size: $font-size-md;
        }

        .popup-close {
          display: none;
        }
      }

      .popup-body {
        & > div {
          width: 400px;
          margin: 20px auto 0;
        }
      }

      .popup-footer {
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;

        .btn-success,
        .btn-flat {
          @extend .font-secondary;
          height: 40px;
          font-size: $font-size-sm;
          font-weight: 100;
        }

        .btn-flat {
          background-color: $liat-red;
          min-width: 110px;
          color: $white;
        }
      }
    }
  }

  .popup-dialog {
    padding: 15px;
    width: 100%;
  }

  .popup-header {
    border-bottom: 1px solid $heather;
    justify-content: space-between;
    padding-bottom: 10px;

    .title {
      @extend .font-light;
      text-transform: inherit;
      margin: 0;
    }
  }

  .popup-body {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;

    h5 {
      @extend .font-light;
      font-size: $font-size-sm;
      margin-top: 0;
    }
  }

  .popup-footer {
    width: 100%;

    &--left {
      justify-content: flex-start;
    }

    &--right {
      justify-content: flex-end;
    }

    &--center {
      justify-content: center;
    }
  }

  .popup-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $black;
    opacity: 0.5;
  }

  .btn-flat {
    @extend .font-light;
    text-transform: capitalize;
    padding: 0;
    margin-left: 20px;
  }

  .material-icons {
    color: $heather;
    cursor: pointer;
  }
}
