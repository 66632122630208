.page-content__wrapper-columns,
.master-class__asset-wrapper {
  @extend %display-flex;
  justify-content: space-between;
}

.master-class {
  &__asset-wrapper {
    align-items: flex-start;

    &__controls {
      width: 25%;
      min-width: 307px;
      margin-top: 8px;
    }
  }
}
