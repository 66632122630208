.checkbox-wrapper {
  margin: 0;

  .checkbox-content {
    .checkbox {
      @extend .font-light;
      display: inline;
      padding-left: 17px;
      font-size: $font-size-tiny;
    }
  }
}

[type='checkbox'] + span:not(.lever) {
  height: 0;
  margin-bottom: 10px;
}

[type='checkbox'].filled-in:not(:checked) + span:not(.lever):after,
[type='checkbox'].filled-in:checked + span:not(.lever):after {
  background-color: $white;
  z-index: 0;
  border: 1px solid $black;
  width: $checkbox-width-sm;
  height: $checkbox-height-sm;
  border-radius: 0;
}

[type='checkbox'].filled-in:checked + span:not(.lever):after {
  background-color: $white;
}

[type='checkbox'].filled-in:checked + span:not(.lever):before {
  top: -4px;
  left: -2px;
  border-right: 2px solid $black;
  border-bottom: 2px solid $black;
}
