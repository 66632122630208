.caret-up {
  @include caret($white, 5px, 'up');

  &--active {
    @include caret($stone-green, 5px, 'up');
  }
}

.caret-down {
  @include caret($white, 5px, 'down');

  &--active {
    @include caret($stone-green, 5px, 'down');
  }
}
