.page-content {
  &__product-form-wrapper {
    padding: 0 11px;

    .basic-multi-select {
      margin-top: 5px;
    }

    .page-content__form {
      margin-top: 0;
      padding: 3px 0 10px;
    }
  }

  &__product-form {
    @extend %display-flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
}

.product-form {
  &__button-group {
    margin-top: 22px;

    .btn-action--small {
      margin-right: 25px;
    }
  }

  &__attribute-url {
    @extend .font-light;
    @extend %display-flex;
    font-size: $font-size-tiny;
    border: 1px solid $heather;
    margin-top: 5px;
    height: 23px;
    vertical-align: middle;
    padding: 6px;

    .form__textbox {
      border: none;
      height: 12px;
      padding: 0;
    }

    &--error {
      border-color: $error-color;
    }
  }

  &__text {
    margin-right: 3px;
  }

  &__custom-attributes {
    padding-right: 12px;
    padding-bottom: 20px;

    .btn-action--small {
      margin-top: 2px;
      margin-left: 15px;
    }

    .modal {
      display: none;
    }
  }
}
