/* Variable for font-family */
@font-face {
  font-family: 'AvenirLight';
  src: url('../../fonts/AvenirLight.ttf');
}

@font-face {
  font-family: 'AvenirBlack';
  src: url('../../fonts/AvenirBlack.ttf');
}

@font-face {
  font-family: 'TradeGothic';
  src: url('../../fonts/TradeGothic.ttf');
}

@font-face {
  font-family: 'RobotoBold';
  src: url('../../fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'RobotoRegular';
  src: url('../../fonts/Roboto-Regular.ttf');
}

.font-light,
.font-bold,
.font-medium,
.font-trade,
.font-primary {
  -webkit-font-smoothing: antialiased;
}

.font-light {
  font-family: 'AvenirLight';
}

.font-bold {
  font-family: 'AvenirBlack';
}

.font-medium {
  font-family: 'AvenirLight';
  font-weight: 500;
}

.font-trade {
  font-family: 'TradeGothic';
}

.font-secondary {
  font-family: 'RobotoBold';
}

.font-primary {
  font-family: 'RobotoRegular';
}

/* Variable for font-family */
$base: 'AvenirLight';
$bold: 'AvenirBlack';
$medium: 'TradeGothic';
$secondary: 'RobotoBold';
$primary: 'RobotoRegular';

/* Variable for font-size */
$font-size-tiny: pxToRem(12);
$font-size-xs: pxToRem(14);
$font-size-sm: pxToRem(16);
$font-size-md: pxToRem(18);
$font-size-lg: pxToRem(20);
$font-size-xl: pxToRem(28);
$font-size-xxl: pxToRem(32);
$font-size-xxxl: pxToRem(40);

/* Variable for title */
$font-sub-title: pxToRem(22);
