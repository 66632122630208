.combine-form__container,
.combine-form__wrapper-header,
.combine-form__search-bar {
  @extend %display-flex;
}

.combine-form {
  &__container {
    align-items: flex-start;
    margin-top: 40px;
  }

  &__wrapper-header {
    align-items: center;
    justify-content: space-between;
  }

  &__table {
    td {
      @extend .font-bold;
    }
  }

  &__list {
    width: calc(100% - #{$combine-form-width});
    max-width: $combine-list-width;
    padding-left: 0 !important;

    table {
      margin-top: 0;

      tbody {
        max-height: 700px;
        overflow: auto;

        tr {
          td {
            cursor: pointer;
          }
        }
      }

      .table-default__head-cell,
      .table-default__body-cell {
        width: 100%;
      }

      tbody tr,
      thead tr {
        @extend %display-flex;
        justify-content: space-between;
      }

      thead,
      tbody {
        display: block;
      }

      thead {
        position: relative;

        tr {
          th:nth-child(3) {
            text-align: center;
          }
        }
      }
    }
  }

  &__search-bar {
    justify-content: flex-end;
    margin-top: 40px;
    width: calc(100% - (28px + #{$combine-form-width}));
    max-width: $combine-list-width;

    .input-search {
      padding-left: 20px;
    }
  }

  &__header--equipment {
    padding-bottom: 40px;
  }

  &__add-form {
    width: 100%;
    margin-left: 22px;
    margin-top: 14px;

    h4 {
      @extend .font-primary;
      font-size: $font-size-md !important;
      margin: 0 !important;
    }

    .form__textbox {
      margin: 10px 10px 0 0 !important;
    }
  }

  &__form {
    max-width: $combine-form-width;
    min-width: $combine-form-width;

    .input-add {
      margin-right: 25px;
      padding-left: 20px;
      margin-top: 42px;
      margin-bottom: 30px;
    }

    .btn-add {
      text-transform: capitalize;
    }

    label {
      display: block;
      margin-top: 20px;
      margin-left: 30px;
    }
  }

  .master-class__remove-cell,
  .master-class__group-icon {
    width: 300px;
    padding: 15px 0;
  }

  .master-class__group-icon {
    text-align: center;
  }

  .form__label--success:first-letter,
  .form__label--error:first-letter {
    text-transform: capitalize;
  }
}
