.radio__wrapper {
  margin: 0;

  [type='radio']:not(:checked) + span,
  [type='radio']:checked + span {
    @extend .font-light;
    display: inline;
    font-size: $font-size-tiny;
    padding-left: 18px;
  }

  [type='radio']:not(:checked) + span:before,
  [type='radio']:not(:checked) + span:after,
  [type='radio']:checked + span:before,
  [type='radio']:checked + span:after,
  [type='radio'].with-gap:checked + span:before,
  [type='radio'].with-gap:checked + span:after {
    border-radius: 0;
    border-width: 1px;
    width: $checkbox-width-sm;
    height: $checkbox-height-sm;
    margin: 0;
  }

  [type='radio']:not(:checked) + span:before,
  [type='radio']:not(:checked) + span:after,
  [type='radio']:not(:checked) + span:not(.lever):after,
  [type='radio']:checked + span:not(.lever):after {
    border: 1px solid $black;
  }

  [type='radio']:disabled + span:before,
  [type='radio']:not(:checked) + span:after {
    border-color: $disabled-radio;
    background-color: $disabled-radio;
  }

  [type='radio']:checked + span:not(.lever):after {
    background-color: $black;
  }
}
