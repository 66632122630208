.label-group__text {
  @extend .font-secondary;
}

.label-group {
  justify-content: space-between;
  direction: ltr;
  margin: 0 20px 10px 10px;
  width: 200px;

  &:hover {
    &,
    i {
      color: $liat-red;
    }
  }
}

.modal-single-column {
  padding-right: 50px;
  flex-direction: column;
}

.label-group__text {
  text-align: left;
  font-size: $font-size-tiny;
  margin: 0;
  display: inline-block;
  margin-top: -5px;
}
