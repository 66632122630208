/* Select */

// Common classes
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.select-menu-text {
  @extend .font-light;
  font-size: $font-size-tiny;
  color: $select-menu-color;
}

.no-padding-vertical {
  padding-top: 0;
  padding-bottom: 0;
}

.no-margin-vertical {
  margin-top: 0;
  margin-bottom: 0;
}

.control-border {
  border-color: $stone-green;
  box-shadow: none;
}

.basic-multi-select {
  // Controls
  .select__control,
  .select__menu {
    @extend .select-menu-text;
    font-size: $font-size-tiny;
    border-radius: 0;
    border-color: $input-border;
  }

  .select__menu-list {
    text-align: left;

    .select__option--is-selected {
      background-color: $select-menu-hover-item;
    }
  }

  .select__placeholder {
    @extend .select-menu-text;
  }

  .select__control,
  .select__indicators {
    min-height: 21px;
  }

  &--error {
    .select__control {
      &,
      &:hover {
        border-color: $error-color;
        box-shadow: none;
      }
    }
  }

  .select__control {
    .select__value-container {
      padding: 0 8px;

      div:last-child {
        @extend .no-margin-vertical;
      }
    }

    &:focus,
    &:hover,
    &--is-focused,
    &--menu-is-open {
      @extend .control-border;
    }
  }

  // Indicators
  .select__indicators {
    .select__indicator-separator {
      @extend .hide;
    }

    .select__indicator {
      @extend .no-padding-vertical;
    }
  }

  .select__clear-indicator,
  .select__dropdown-indicator {
    color: $input-border;
  }

  .select__dropdown-indicator {
    svg {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid $input-border;
    }
  }

  // Dropdown menu
  .select__menu {
    box-shadow: 0 0 0 1px $input-border, 0 4px 11px $input-border;

    .select__option {
      @extend .select-menu-text;

      &--is-focused,
      &:hover {
        background-color: $select-menu-hover-item;
      }
    }

    .btn-add {
      background-color: transparent;
      color: $input-border;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      i {
        margin-right: 5px;
      }
    }
  }

  .select__value-container--is-multi {
    @extend .select-menu-text;
    padding: 2px 6px;
  }

  // Selected labels
  .select__single-value,
  .select__multi-value__label {
    @extend .select-menu-text;
    font-size: $font-size-tiny;
    padding-top: 3px;
    padding-bottom: 0;
  }

  .select__multi-value {
    margin: 2px;
    border-radius: 0;

    &__remove {
      &:hover {
        background-color: transparent;
      }
    }
  }
}

// Customize loading indicator
.select__menu {
  // position: relative !important;

  .menu-list-content {
    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 300px;
      z-index: $z-index-max;
      background-color: rgba(255, 255, 255, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--loading {
      overflow-y: hidden !important;
      padding: 0 !important;
      position: relative;
    }

    &__spinner {
      border-color: $stone-green;
    }
  }
}
