$tiny-cell-width: 80px;
$lg-cell-width: 200px;
$xl-cell-width: 350px;

th.master-class__multi-song-track {
  padding-left: 35px !important;
}

.master-class {
  margin-top: 28px;

  &__header {
    margin-bottom: 15px;

    &__title {
      @extend .font-trade;
      font-size: 22px;
      text-transform: uppercase;
      margin: 0;
      min-width: 250px;
    }

    &__form-wrapper {
      display: flex;
      align-items: flex-end;
      margin-left: 10px;
      margin-top: 8px;

      .form__textbox {
        width: 329px;
        min-width: 329px;
      }

      .form__label {
        font-size: 10px;
        margin-left: 38px;
        color: $black;
        width: 300px;
        text-align: center;
      }
    }
  }

  &--view-only {
    min-width: 135px;
    max-width: 135px;
  }

  &__song-row {
    margin-top: 5px;
  }

  &__multi-song-track:not(input) {
    min-width: 170px !important;
  }

  &__song-input-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;

    &,
    &:focus {
      outline: none;
    }

    i {
      opacity: 0.7;
    }
  }

  &__btn-remove-songs {
    color: $liat-red;

    &,
    i {
      font-size: $font-size-sm;
    }

    &--hide {
      visibility: hidden;
    }
  }

  &__btn-multi-songs {
    justify-content: flex-end;
    color: $dove-gray;
    margin-top: 5px;
    cursor: pointer;
    width: 100%;
    text-align: right;

    i {
      margin-right: 5px;
      margin-left: 5px;
    }

    &,
    i {
      font-size: $font-size-tiny;
    }
  }

  &__custom-icon {
    padding-left: 25px;
  }

  &__track-number {
    margin-right: 90px;
  }

  &__label {
    vertical-align: bottom;
  }

  &__cell {
    vertical-align: top !important;
    user-select: none;

    &__select {
      max-width: 220px;
      min-width: 220px;

      .select__multi-value {
        max-width: 100px;
      }

      .master-class__select {
        width: 200px;
      }
    }

    &__duration {
      min-width: 160px !important;

      .form__textbox {
        width: 140px !important;
      }
    }

    &__input {
      .form__textbox--index {
        width: 100%;
      }

      .form__textbox {
        width: 130px;
      }

      .form__textbox--url {
        width: 200px;
      }

      .page-content__row {
        margin-top: 0;
      }
    }
  }

  &__delete-track,
  &__row .master-class__group-icon {
    min-width: 100px;
  }

  &__insert-track {
    min-width: 30px !important;

    i {
      line-height: 10px;
    }
  }

  &__row {
    border: none;

    .master-class__group-icon {
      padding-bottom: 23px;
      text-align: center;
    }
  }

  &__date {
    width: 100px !important;
  }

  &__tiny-cell {
    width: $tiny-cell-width !important;
    min-width: $tiny-cell-width !important;
    padding-left: 10px !important;
  }

  &__lg-cell {
    width: $lg-cell-width !important;
    max-width: $lg-cell-width !important;
  }

  &__xl-cell {
    width: $xl-cell-width !important;
    max-width: $xl-cell-width !important;

    &__select {
      width: calc(#{$xl-cell-width} - 50px) !important;

      .select__multi-value {
        max-width: 100% !important;
      }
    }
  }

  .master-class__group-button {
    @extend %display-flex;
  }

  &__sub-header {
    display: table-row;

    td {
      padding: 0 0 5px !important;
    }
  }

  &__select {
    min-width: 185px;

    .select__single-value {
      width: 100%;
    }
  }

  &__option-group {
    @extend %display-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;
  }

  .option-group__track-number {
    padding-left: 20px;
    margin: 0;
  }

  .option-group__track-name {
    text-align: left;
    width: 65px;
    margin: 0;
  }

  &__double-columns {
    @extend %display-flex;
    align-items: center;
  }

  &__track-name {
    margin-left: 20px;
  }

  &__body {
    width: 100%;
    overflow-x: auto;

    .btn-action--small {
      padding: 0;
    }

    .btn-add-track {
      @extend .font-light;
      text-align: left;
      background-color: transparent;
      color: $stone-green;
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      i {
        margin-right: 5px;
      }
    }

    .custom-input {
      display: none;
    }

    table {
      table-layout: fixed;

      &.table-full-width {
        min-width: 100%;
      }

      td,
      .master-class__label {
        vertical-align: top;
      }

      td {
        padding: 10px;
        width: 130px;
      }

      .master-class__label {
        min-width: 100px;
        padding: 10px;
      }

      .master-class__textbox {
        min-width: 145px;
      }

      .master-class__selectbox {
        min-width: 220px;
      }

      .master-class__center-cell {
        text-align: center;
      }

      .master-class__cell__date-picker {
        padding: 3px 10px 6px;
      }

      th {
        padding: 5px 10px;
        font-weight: normal;
      }

      th,
      td {
        @extend .font-light;
        font-size: $font-size-tiny;
        color: $master-class-text-color;
        overflow: hidden;
        text-overflow: ellipsis;
        width: auto;
      }
    }

    &__variant {
      @extend %display-flex;

      input {
        width: 153px;
        margin-right: 13px;
      }
    }

    thead {
      display: table-row;
    }

    thead th:nth-child(2),
    tbody td:nth-child(2) {
      min-width: 135px;
      padding-left: 10px;
      padding-right: 0;
    }

    tbody {
      height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      display: block;

      tr td {
        .form__textbox {
          width: 124px;
        }

        i {
          cursor: pointer;
        }
      }

      .master-class--view-only {
        padding-top: 12px;
      }
    }
  }

  &__workout-table:nth-child(odd) {
    background-color: $wild-sand;
  }
}

.master-class__body::-webkit-scrollbar {
  height: 7px;
}

.sortable-list {
  background-color: #ddd;

  td,
  label {
    @extend .font-light;
    vertical-align: top;
    font-size: $font-size-tiny;
    color: $master-class-text-color;
  }

  input.master-class__center-cell {
    text-align: center;
  }

  button {
    background-color: transparent;
    border: none;
  }
}

// CUSTOMIZE master clas body height

// Plan page
.page-content__plan-wrapper,
.release-wrapper {
  .master-class__body {
    tbody {
      height: calc(100vh - 400px);
    }
  }
}

.workout__form--collapsed {
  .master-class__body {
    tbody {
      height: calc(100vh - 70px);
      max-height: calc(100vh - 455px);
    }
  }
}

// Workout page
.workout__form:not(.workout__form--collapsed) {
  .master-class__body {
    tbody {
      height: calc(100vh - 400px);
      max-height: 340px;
    }
  }
}

// Release page
.release-wrapper {
  .master-class__body {
    tbody {
      max-height: 650px;
    }
  }
}

// Program page
.program__master-class__edit {
  .master-class__body {
    tbody {
      height: calc(100vh - 200px);
      max-height: 700px;
    }
  }
}
