.container {
  margin-top: pxToRem(40);
  width: 100%;
  height: 100%;

  .form__signout-successfull {
    font-size: $font-size-xl;
    color: $stone-green;
    margin-bottom: 42px;
  }

  .form__register-form {
    padding-bottom: 30px;

    .form__input-group {
      position: relative;
    }

    .icon-eyes {
      position: absolute;
      right: 8px;
      bottom: 30px;
      cursor: pointer;
    }

    .link__footer {
      margin-bottom: 0;
    }
  }
}

.form-wrapper {
  width: pxToRem(400);
  margin: 0 auto;
  border: solid 1px $gray;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.input {
  margin-top: pxToRem(20);
  border-radius: $border-radius-sm;
  border: solid 1px $gray;
  height: pxToRem(40);
  padding: pxToRem(10);
}

.btn-sign-in {
  padding: 0 !important;
  margin-top: pxToRem(20);
}
