.page-content {
  position: relative;
}

.loading-indicator {
  @extend %display-flex;
  background-color: $main-black;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  opacity: 0.8;
  justify-content: center;
  align-items: center;

  &--internal {
    background-color: $white;
    z-index: $z-index-max;
    opacity: 0.8;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &--fixed {
    background-color: $abbey;
    width: 100%;
    min-height: 100vh;
    height: 100vh !important;
    opacity: 1;
  }

  &--mixed {
    width: 100%;
    min-height: 100vh;
    height: 100vh !important;
    position: fixed;
  }
}

.spinner {
  &--tiny.preloader-wrapper.small {
    width: 15px;
    height: 15px;

    .circle-clipper .circle {
      border-width: 1px;
    }

    .spinner-custom {
      border-color: $white;
    }
  }
}
