.duration__wrapper {
  border: 1px solid $heather;
  width: 100%;
  background-color: $white;

  &--error {
    border-color: $error-color;
  }

  @extend %display-flex;
  align-items: center;
  justify-content: flex-end;

  &__textbox {
    border: none;
    outline: none;
    width: calc(100% / 3);
    text-align: center;
  }

  &__label {
    margin-left: 10px;

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  &__label,
  &__textbox {
    @extend .font-light;
    font-size: $font-size-tiny;
    color: $tundora;
  }

  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
