.App {
  text-align: center;
  height: 100vh;
  overflow-y: auto;
}

.App-header {
  @extend %display-flex;
  height: 70px;
  justify-content: space-between;
  align-items: center;

  h1 {
    @extend .font-bold;
    font-size: $font-size-xl;
    color: $white;
    text-align: center;
    width: 100%;
  }
}

.App-header--default {
  background-color: $main-black;
  color: $black;
}

.App-header--dark {
  background-color: $black;
  color: $white;
}

.App-header--light {
  background-color: $stone-green;
  color: $liat-red;
}

.App-body {
  @extend %display-flex;
  width: 100%;

  &__register-form {
    @extend %display-flex;
    background-color: $dove-gray;
    align-items: center;
  }
}

.App-sidebar {
  @extend %display-flex;
  background-color: $main-black;
  min-height: 100vh;
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.5s ease-in-out;
  position: fixed;
  // ensure app-sidebar is twice the z-index max
  z-index: $z-index-max * 2;
}

.App-content {
  background-color: $white;
  transition: width 0.5s ease-in-out;
}

.button-theme {
  margin: 0 20px;
}

.content-center {
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.form__textbox--disabled {
  background-color: $concrete;
}

.page-content {
  padding: 30px 50px;
  text-align: left;

  &__btn-group {
    .dropdown {
      margin-right: 20px;
    }

    .btn-success--large {
      min-width: 130px;
    }
  }

  &__group-title,
  &__table-content__header,
  &__btn-group,
  .table-content__actions-group {
    @extend %display-flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__table-content {
    &__header {
      max-width: 490px;
    }
  }

  // Is used for Add and New
  &--form {
    background-color: $gray;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }

  &--processing,
  &__form {
    position: relative;
  }

  &__form {
    background-color: $white;
    padding: 3px 4px 0 4px;
    margin-top: 20px;

    &__input-title {
      @extend .font-trade;
      font-size: $font-size-xs;
      text-transform: uppercase;
      margin-bottom: 3px;
    }

    &__header {
      @extend %display-flex;
      align-items: center;
    }

    .btn-delete {
      min-width: 185px;
      margin-right: 12px;
    }
  }

  &__workout-plan {
    padding-left: 12px;

    .page-content__master-class-track {
      td {
        width: auto;
      }
    }

    .page-content__form__error {
      margin-top: 10px;
      margin-left: 0;
    }
  }

  &__main-title {
    padding-bottom: 5px;
  }

  &__colum {
    width: 30.2%;
    padding-left: 0;
  }

  h2:not(.master-class__header__title) {
    @extend .font-primary;
    font-size: $font-size-xxl;
    margin: 0;
  }

  &__sub-title {
    @extend .font-primary;
    font-size: $font-size-sm;
    justify-content: space-between;
    margin-top: 8px;
    // margin-right: 46px;

    &,
    span {
      @extend %display-flex;
      align-items: center;
    }

    &__icon {
      width: 20px;

      img {
        width: auto;
        margin-right: 5px;
      }
    }

    .btn-edit-multi-workout {
      margin-right: 30px;
      width: 185px;
      padding: 0 20px;
      font-size: $font-size-tiny;
    }
  }

  h4 {
    @extend .font-primary;
    font-size: $font-size-md;
    text-transform: uppercase;
    margin: 22px 0 10px;
  }

  /* List page content */
  &__list {
    // padding-left: 18px;
    // padding-right: 28px;

    &__note {
      @extend .font-primary;
      font-size: $font-size-sm;
      line-height: 19px;
    }
  }

  &__details {
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
  }
}

@media only screen and (max-width: 1360px) {
  .page-content {
    &__availability {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1220px) {
  .select__value--product {
    .select__value-container {
      min-height: 33px;
    }
  }

  .basic-multi-select .select__control .select__value-container {
    align-items: flex-start;
  }
}
