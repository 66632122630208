/*
 * mixin: transform
 * Used to set transform properties with cross browser
*/
@mixin transform($transforms...) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

/*
 * mixin: transition
 * Used to set transition properties with cross browser
*/
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

/*
 * mixin: boxShadow
 * Used to set transition properties with cross browser
*/
@mixin boxShadow($box-shadow...) {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

/*
 * mixin: animation
 * Used to set transition properties with cross browser
*/
@mixin animation($animation...) {
  -webkit-animation: $animation; /* Safari 4+ */
  -moz-animation: $animation; /* Fx 5+ */
  -o-animation: $animation; /* Opera 12+ */
  animation: $animation; /* IE 10+, Fx 29+ */
}

/*
 * mixin: keyFrames
 * Used to set transition properties with cross browser
*/
@mixin keyFrames($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

/*
 * mixin: flexContainer
 * Used to set the flex css property
*/
@mixin flexContainer($justifyContent, $alignItems, $flexDirection) {
  align-items: $alignItems;
  justify-content: $justifyContent;
  flex-direction: $flexDirection;
}

/*
* mixin: truncate
*/
@mixin truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*
* function: pxToRem
* Used to convert unit 'px' to 'rem'
*/
@function pxToRem($pixels, $context: 16) {
  @return $pixels / $context * 1rem;
}

@mixin caret($color, $size, $position) {
  width: 0;
  height: 0;

  @if $position == 'up' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }

  @if $position == 'down' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  }

  @if $position == 'left' {
    border-right: $size solid $color;
    border-bottom: $size solid transparent;
    border-top: $size solid transparent;
  }

  @if $position == 'right' {
    border-left: $size solid $color;
    border-bottom: $size solid transparent;
    border-top: $size solid transparent;
  }
}
