.page-content__publishing {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 70px);

    .btn-revert {
      max-width: 170px;
      text-transform: capitalize;
      margin-left: 20px;
    }
  }

  @extend %display-flex;
  justify-content: space-between;
  margin-top: 30px;

  .table-default {
    th:first-child,
    td:first-child {
      display: none;
    }

    &__heading th,
    &__body td {
      padding-left: 17px;
      padding-right: 17px;
    }

    &__heading th:nth-child(2),
    &__body td:nth-child(2),
    &__heading th:last-child,
    &__body td:last-child {
      width: 120px !important;
      min-width: 120px !important;
    }

    &__heading {
      th {
        @extend .font-bold;
      }
    }

    &__body {
      .redundant-cell--date {
        padding-left: 0;
        text-align: center;
      }
    }

    td {
      @extend .font-light;
    }
  }

  .combine-form__container {
    margin-top: 0;
  }

  .combine-form__list {
    width: 100%;
  }

  .btn-publishing {
    width: 150px;
  }

  .btn {
    text-transform: uppercase;
  }

  .btn-approve {
    margin-left: 50px;
  }

  .btn-error {
    margin-left: 10px;
  }
}

.publishing-status-wrapper {
  margin-top: 40px;

  .publishing__action,
  .publishing__status,
  .publishing__combine {
    margin-bottom: 30px;
  }

  .publishing__combine {
    display: flex;
    align-content: center;

    .publishing__status {
      margin: 0;
    }
  }

  .publishing__result {
    margin: 0;
  }

  .number-order {
    margin-right: 40px;
  }

  .publishing {
    @extend %display-flex;

    .publishing-status--begin-details {
      width: 150px;
      justify-content: space-between;

      .icon-groud {
        position: relative;
        border: 1px solid $dove-gray;
        height: 13px;
        width: 13px;
        min-width: 13px;
        border-radius: 50%;
        cursor: pointer;
      }

      .material-icons {
        position: absolute;
        font-size: $font-size-sm;
        left: -3px;
        bottom: -2px;
        font-weight: 700;
      }

      .icon-checked {
        color: $silver-chalice;
        font-size: $font-size-md;
      }

      .icon-error {
        color: $torch-red;
        font-size: $font-size-sm;
      }

      .page-content__list__note {
        font-family: $secondary;
      }
    }
  }
}

.publishing-error-wrapper {
  position: absolute;
  width: 300px;
  z-index: $z-index-xs;
  left: 20px;
  background-color: $gallery;
  padding: 5px 10px 10px 15px;
  word-wrap: break-word;

  .error-title {
    @extend .font-light;
    font-size: $font-size-md;
    color: $error-color;
  }
}
