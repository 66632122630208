.modal-wrapper {
  padding: 20px;
  min-height: 270px;

  .modal-header {
    @extend %display-flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $heather;
  }

  .material-icons {
    color: $heather;
    cursor: pointer;
  }

  .btn-flat {
    padding: 0 10px;
  }

  .modal-title,
  .btn-flat {
    @extend .font-light;
    text-transform: capitalize;
  }
}

.modal--small {
  width: 40%;
}

.modal {
  overflow: visible;
  border-radius: $border-radius-sm;

  .modal-content {
    padding: 0;
  }
}
