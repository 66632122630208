.react-datepicker-wrapper,
.react-datepicker__input-container,
.form__date-picker--input {
  width: 100%;
}

.react-datepicker__time-container,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100px;
}

.form {
  &__date-picker {
    @extend %display-flex;
    align-items: center;
    justify-content: space-between;

    &--input {
      @extend .font-light;
      border: none;
      outline: none;
      font-size: $font-size-tiny;
      color: $tundora;
    }
  }

  &__textbox {
    &,
    input {
      cursor: default;
    }

    .material-icons {
      color: $heather;
      font-size: $font-size-xxl;
      width: 23px;
      height: 30px;
    }
  }
}
