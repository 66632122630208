.color-picker {
  @extend %display-flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;

  &__wrapper {
    @extend %display-flex;
    align-items: center;
    width: $input-color-width;
    height: $input-height-sm;
    border: 1px solid $heather;
    padding: 5px 0 3px 5px;
  }

  &__content,
  &__content__input {
    @extend .font-light;
    font-size: $font-size-tiny;
    text-transform: uppercase;
    color: $tundora;
  }

  &__content__input::placeholder {
    text-transform: none;
  }

  &__content {
    &__input {
      border: none;
      width: 100%;
      padding-left: 2px;

      &:focus {
        outline: none;
      }
    }
  }

  .btn {
    &--no-color-picker,
    &--color-picker {
      width: 23px;
      height: $button-height-sm;
      margin-left: 6px;
      padding-bottom: 2px;

      &:focus {
        outline: none;
      }
    }

    &--color-picker {
      border: none;
    }

    &--no-color-picker {
      border: 1px solid $heather;
      background-color: $white;
    }
  }
}

.sketch-picker {
  position: absolute;
  z-index: $z-index-xs;
}
