@charset 'UTF-8';

// Base styles
@import 'assets/styles/Main.scss';

// Components styles
@import 'components/checkbox/CheckBox.scss';
@import 'components/imageBrowse/ImageBrowse.scss';
@import 'components/radio/Radio.scss';
@import 'components/autoComplete/AutoComplete.scss';
@import 'components/datePicker/DatePicker.scss';
@import 'components/duration/Duration.scss';
@import 'components/confirmDelete/ConfirmDelete.scss';
@import 'components/asset/Assets.scss';
@import 'components/tab/Tabs.scss';
@import 'components/fileBrowse/FileBrowse.scss';
@import 'components/registerForm/RegisterForm.scss';
@import 'components/pagination/Pagination.scss';
@import 'components/dropdown/Dropdown.scss';

// Pages styles
@import 'pages/sign-in/index.scss';
@import 'pages/programs/index.scss';
@import 'components/masterTemplate/MasterClassTemplate.scss';
@import 'pages/programs/components/ProgramForm.scss';
@import 'pages/products/components/ProductForm.scss';
@import 'pages/workouts/components/WorkoutForm.scss';
@import 'pages/workouts/components/bulk/BulkWorkout.scss';
@import 'pages/common/CombineForm.scss';
@import 'pages/plans/components/Plans.scss';
@import 'pages/publishing/index.scss';
@import 'pages/releases/components/ReleaseForm.scss';
@import 'pages/not-found/NotFound.scss';
@import 'pages/tier-chargify-product/components/TierChargifyMultipleProductForm.scss';
@import 'pages/tier-chargify-product/components/DeleteTierChargifyRelationship.scss';

// Layout styles
@import 'layouts/main/components/Aside.scss';
@import 'layouts/index.scss';
@import 'stories/Story.scss';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: $font-size-sm;
}
