.page-content {
  &--form {
    height: 100%;
  }

  &__form {
    justify-content: space-between;
    min-height: calc(100% - 80px);

    .btn-success--large {
      margin-left: 12px;
      padding: 0;
    }

    &__error {
      margin-left: 12px;
    }

    &__footer {
      @extend %display-flex;
      margin-bottom: 12px;
      justify-content: space-between;
      margin-top: 20px;
    }

    &__master-class {
      padding: 12px;

      a {
        @extend .font-light;
        font-size: $font-size-tiny;
        color: $black;
      }
    }
  }

  &__basic-details {
    padding-right: 12px;
  }

  &__customisation {
    margin-right: 35px;
    position: relative;
  }

  &__row {
    margin-top: 15px;
  }

  .program-director {
    margin-top: 20px;
  }

  &__single-column {
    width: 30.2%;
  }

  &__column {
    &__availability,
    &__customisation {
      width: 43.4%;
    }
  }

  &__double-columns {
    width: calc(100% - 30.2%);
  }

  &__form,
  &__wrapper,
  &__double-columns,
  .master-workout,
  .master-workout__details-variant,
  .program__master-class {
    @extend %display-flex;
    padding-bottom: 0;
  }

  /* Master class template */
  &__wrapper {
    margin-left: 12px;
    margin-top: 15px;
  }

  &__form {
    .btn-success--large,
    &__error {
      margin-left: 12px;
    }
  }

  &__double-columns,
  &__form {
    flex-direction: column;
  }

  &__master-workout {
    margin: 25px 0 25px 7px;
    width: 800px;
  }

  &__master-class-track {
    position: relative;
    overflow-x: auto;
    padding: 0 12px;

    .master-class {
      margin-top: 0;
    }

    .form-row {
      display: flex;
      align-items: flex-end;
      margin: 7px 0 24px;
    }
  }

  .program__master-class {
    align-items: center;

    .btn-add-track {
      margin: 22px 50px 10px;
    }
  }
}

.master-workout {
  &__title {
    font-size: $font-sub-title;
    padding: 0;
    margin: 0;
  }

  &__serial {
    vertical-align: top;
    padding: 6px;
    width: 150px;
  }

  &__title,
  &__sub-title {
    text-transform: uppercase;
    @extend .font-trade;
  }

  &__sub-title {
    font-size: $font-size-tiny;
  }

  &__row {
    border: none;
  }

  &__col {
    padding: 6px;
    vertical-align: top;
    width: 130px;
  }

  &__details-variant {
    justify-content: flex-start;
  }

  &__variant {
    text-align: center;
    padding-right: 36px;
  }

  &__col,
  &__details-variant,
  &__textbox::placeholder {
    @extend .font-light;
    font-size: $font-size-tiny;
  }

  &__checkbox {
    margin-left: 5px;
    width: 0;
    height: 0;
    padding-right: 36px;

    [type='checkbox'] + span:not(.lever) {
      height: 0;
      vertical-align: middle;
    }
  }

  &__textbox {
    width: 250px;
    margin-right: 10px;

    &::placeholder {
      text-transform: none;
    }
  }

  &__select {
    min-width: 260px;
    width: 100%;

    .select__multi-value {
      max-width: 80px;
    }
  }

  &__textbox-track {
    width: 90px;
  }

  &__textbox-variant {
    width: 40px;
    margin-right: 5px;
  }

  &__textbox-number {
    width: 40px;
  }
}

.program__view-only {
  table {
    thead th,
    tbody td {
      min-width: $table-column-width;
      max-width: $table-column-width;
    }
  }
}

.program__master-class__edit {
  .master-class__body thead th:first-child,
  .master-class__body tbody td:first-child {
    min-width: 90px;
  }
}
