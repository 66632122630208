$dropdown-solid-height: 40px;

.dropdown {
  position: relative;

  &--disabled {
    label {
      background-color: $dropdown-disable !important;
      cursor: not-allowed;
    }
  }

  &--solid {
    &.dropdown--selected {
      label {
        background-color: $dove-gray !important;
      }
    }

    label {
      display: flex;
      justify-content: space-between;
      background-color: $stone-green;
      color: $white;
      height: $dropdown-solid-height;
      padding-left: 20px;
      border-radius: $border-radius-sm;
      min-width: 136px;

      &,
      i {
        line-height: $dropdown-solid-height;
      }

      i {
        min-width: $dropdown-solid-height;
        text-align: center;
        font-size: $font-size-xxl;
        margin-left: 10px;
        border-left: 1px solid $white;
      }
    }

    .dropdown__items {
      margin-top: $dropdown-solid-height;
      padding: 0 !important;
      min-width: 160px;

      li {
        &:first-child {
          margin-top: 5px;
        }

        &:last-child {
          margin-bottom: 5px;
        }

        &:hover {
          background-color: $light-gallery;
        }
      }
    }
  }

  &__label {
    @extend .font-secondary;
    text-transform: uppercase;
    user-select: none;
  }

  &__items {
    position: absolute;
    top: 10px;
    right: 0;
    border: 1px solid $black;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: $white;
    border-radius: $border-radius-sm;
    min-width: 110px;
    padding-left: 5px !important;
    padding-right: 5px;
    z-index: $z-index-max;

    li {
      @extend .font-primary;
      padding-top: 13px;
      padding-bottom: 13px;
      text-align: center;

      &:not(.dropdown__items--viewonly) {
        cursor: pointer;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $lighterSilver;
      }
    }
  }
}
