.file-browse {
  @extend %display-flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 280px;
  margin-top: 8px;

  .form__textbox {
    margin: 7px 0;
  }

  &__header {
    @extend .font-trade;
    font-size: $font-size-xs;
    text-transform: uppercase;
    margin-right: 10px;
    width: 100%;
  }

  &__upload {
    @extend .font-light;
    @extend %display-flex;
    align-items: center;
    font-size: $font-size-tiny;
    background-color: $stone-green;
    color: $white;
    height: 23px;
    width: 53px;
    text-align: center;
    line-height: 25px;
    padding: 5px;
    cursor: pointer;
  }

  &__input {
    display: none;
  }
}
