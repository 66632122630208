.form__register-form,
.form__register-form {
  .form-content__title,
  .form-footer,
  .register-form__link {
    @extend %display-flex;
  }
}

.form__register-form,
.form__register-form {
  .form-header,
  .form-footer {
    justify-content: center;
  }
}

.form__register-form {
  .form-header__text {
    text-transform: uppercase;
    font-size: $font-size-xl;
  }

  .form-header {
    height: 200px;
    background-color: $lightSilver;
    display: flex;
    align-items: center;
  }

  .form-content {
    padding: 20px;
    background-color: $white;

    &__link {
      width: 160px;
    }

    .form__text--error {
      text-align: left;
      margin-bottom: 10px;
    }
  }

  .form-footer {
    margin-top: 20px;
    font-size: $font-size-xs;
  }

  .form-wrapper {
    width: 500px;
    box-shadow: 1px 6px 27px 2px $tundora;
    padding: 0;
    border: none;
  }

  .form__textbox {
    @extend .font-light;
    width: 100%;
    padding: 20px 10px;
    margin-bottom: 20px;
    font-size: $font-size-xs;
  }

  .btn-register-form {
    width: 100%;
  }

  .register-form__link {
    color: $stone-green;
    cursor: pointer;
    margin-bottom: 30px;
    font-size: $font-size-xs;
  }

  .link__footer {
    margin-left: 10px;
  }
}
