// Font
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// Color
@import '~materialize-css/sass/components/color-variables';
@import '~materialize-css/sass/components/color-classes';

// Variables
@import '~materialize-css/sass/components/variables';

// Reset
@import '~materialize-css/sass/components/normalize';

// Global
@import '~materialize-css/sass/components/global';
@import '~materialize-css/sass/components/preloader';
@import '~materialize-css/sass/components/tooltip';
@import '~materialize-css/sass/components/modal';
@import '~materialize-css/sass/components/toast';

// Materialize-scss components
@import '~materialize-css/sass/components/buttons';
@import '~materialize-css/sass/components/grid';
@import '~materialize-css/sass/components/forms/checkboxes';
@import '~materialize-css/sass/components/forms/radio-buttons';

.btn {
  @extend .font-secondary;
  font-size: $font-size-sm;
  line-height: $font-size-sm;
  border-radius: $border-radius-sm !important;
  height: 40px;

  &,
  &:hover {
    box-shadow: none;
  }

  &-success {
    background-color: $stone-green;

    &--large {
      min-width: $button-width;
    }
  }

  &-submit {
    width: $button-width;
  }
}

.btn-action--small {
  @extend .font-light;
  font-size: $font-size-tiny;
  text-transform: none;
  height: 23px;
  padding: 0 20px;
}

.icon {
  &__auto-complete-close,
  &__value-remove {
    font-size: $font-size-tiny;
    font-weight: bold;
    margin-left: 5px;
    cursor: pointer;

    &:hover {
      color: $liat-red;
    }
  }

  &__value-remove {
    color: $heather;

    &:hover {
      color: $tundora;
    }
  }
}

.form {
  &__textbox,
  &__textarea {
    @extend .font-light;
    width: 100%;
    border: 1px solid $input-border;
    box-sizing: border-box;
    padding: 8px;
    font-size: $font-size-tiny;

    &:focus {
      outline: none;
    }

    &.short-description {
      height: calc(23px * 3); // 23px is height of textbox
    }
  }

  &__textbox {
    height: $input-height-sm;

    &--type-number {
      width: 40px;
    }

    &--lg {
      width: 224px;
      height: 40px;
    }
  }

  &__textarea--disabled {
    background-color: $concrete;
  }

  &__input-group {
    &--wrapper {
      @extend %display-flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
  }

  &__textbox--error,
  &__textarea--error {
    border: 1px solid $liat-red;
  }

  &__textarea,
  &__textarea--error {
    height: $textarea-height-md;
    resize: none;
  }

  &__text--error {
    color: $liat-red;
    font-size: $font-size-tiny;
    margin: 0;

    &-big {
      font-size: $font-size-sm;
      color: $liat-red;
    }
  }
}

.table-default {
  margin-top: 20px;

  &__redundant-cell {
    width: 60px;
  }

  &.striped > tbody > tr:nth-child(odd) {
    background-color: transparent;
  }

  &.striped > tbody > tr:nth-child(even) {
    background-color: $table-strip-bg;
  }

  &__heading {
    background-color: $main-black;
    color: $white;

    th {
      @extend .font-primary;
      border-radius: 0;
      font-size: $font-size-sm;
      padding-left: 30px;
    }
  }

  &__body {
    td {
      @extend .font-light;
      font-size: $font-size-tiny;
      padding-left: 30px;

      a {
        @extend .font-bold;
        color: $stone-green;
        text-decoration: underline;
      }
    }
  }

  &__sortable-cell {
    @extend %display-flex;
    align-items: center;
  }

  &__sort-control {
    @extend %display-flex;
    flex-direction: column;
    margin-left: 10px;

    &__increase {
      margin-bottom: 3px;
    }

    &__increase,
    &__decrease {
      cursor: pointer;
    }
  }

  &__center-cell {
    text-align: left;
    text-indent: 30px;
  }
}

.material-tooltip {
  @extend .font-light;
  font-size: $font-size-tiny;
  padding: 5px 8px;
  min-height: auto;
  margin-bottom: -10px;
  border-radius: 0;
}
