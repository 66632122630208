.delete-tier-chargify-wrapper {
  .master-class {
    &__body {
      tbody {
        height: unset;
        max-height: 300px;
      }

      th:nth-child(n + 3),
      td:nth-child(n + 3) {
        font-weight: bold;
        color: #e64a19;
      }
    }
  }

  .loading-indicator {
    &--position {
      width: 100%;
      min-height: 100vh;
      height: 100vh !important;
    }
  }
}
