.tab-list-item {
  @extend .font-primary;
  font-size: 22px;
  display: inline-block;
  list-style: none;
  text-transform: uppercase;
  color: $lightSilver;
  margin-top: 10px;
  margin-right: 70px;
  cursor: pointer;
}

.tab-list-active {
  color: $black;
}
