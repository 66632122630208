$controls-height: 30px;

.search-filter,
.search-input-group,
.search-filter__no-result,
.search-filter__no-result--text {
  @extend %display-flex;
}

.search-filter__no-result,
.search-filter__no-result--text {
  align-items: center;
}

.search-filter {
  margin-top: 30px;
  justify-content: flex-start;
  align-items: flex-end;

  &__group-controls {
    label {
      @extend .font-primary;
      font-size: $font-size-sm;
      display: block;
      margin-bottom: 5px;
      color: $main-black;
    }
  }

  &__no-result {
    @extend .font-primary;
    font-size: $font-size-xl;
    color: $tundora;
    justify-content: center;
    height: 200px;
  }

  .basic-multi-select {
    width: 134px;

    .select__control,
    .select__indicators {
      min-height: calc(#{$controls-height} - 2px); // Margin or border
    }
  }

  .input-search {
    border: none;
    color: $tundora;
    height: calc(#{$controls-height} - 2px); // Margin or border
  }

  .search-input-group {
    align-items: center;
    justify-content: space-between;
    width: 253px;
    border: 1px solid $heather;
    margin-left: 10px;
    height: $controls-height;
  }

  .icon-search {
    color: $heather;
    cursor: pointer;
    margin-right: 5px;
  }
}
