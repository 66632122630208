// Animation for common component: Indicator
@include keyFrames(spin) {
  0% {
    @include transform(rotate(0deg));
  }

  100% {
    @include transform(rotate(360deg));
  }
}
