.storybook {
  &__title {
    color: $hit-gray;
    font-size: $font-size-xl;
    margin: pxToRem(20);
    text-align: center;
  }

  &__description {
    color: $hit-gray;
    font-size: $font-size-md;
    margin: pxToRem(20) 0;
  }

  &__content {
    background-color: $oslo-gray;
    color: $white;
    padding: pxToRem(5);
  }

  &__background--primary {
    background-color: $geyser;
  }

  &__background--secondary {
    background-color: $mischka;
  }

  &__guide {
    margin: pxToRem(20) auto;
    padding: pxToRem(25) 0;
    border: pxToRem(1) solid $hit-gray;
    overflow: auto;
    max-height: 30vh;
    width: 90%;
    background: $mischka;
    position: relative;

    &:before {
      content: 'Code guider';
      font-size: pxToRem(10);
      position: absolute;
      top: 0;
      left: 0;
      background: #ccc;
      padding: pxToRem(1);
    }
  }

  &__grid {
    &.grid--primary,
    &.grid--default {
      & > div:last-child {
        background: grey;
      }
    }

    &.grid--2-cols,
    &.grid--3-cols {
      & > div:nth-child(odd) {
        background: darkgrey;
      }

      & > div:nth-child(even) {
        background: grey;
      }
    }

    &__col {
      height: pxToRem(200);
    }
  }

  &__flexbox {
    background-color: $mischka;

    &__item {
      padding: pxToRem(8);
      margin: pxToRem(10);
      background-color: $oslo-gray;
    }
  }

  &__tooltip {
    width: pxToRem(500);
    margin: 0 auto;

    & .tooltip {
      margin: 0 pxToRem(5);
    }

    & .btn {
      text-transform: capitalize;
    }
  }

  &__textbox {
    .common-textbox {
      &__message {
        margin: pxToRem(5);
      }

      &__content {
        margin: 0 pxToRem(5);
      }
    }
  }

  &__drawer__item {
    display: flex;
    align-items: center;
    height: pxToRem(64);
    white-space: nowrap;
    overflow: hidden;
  }

  &__drawer__header {
    height: pxToRem(64);
    border: pxToRem(1) solid $hit-gray;
  }

  &__drawer__toggle {
    width: pxToRem(64);
    height: pxToRem(64);
    outline: none;
    border-radius: 0;
  }

  &__drawer__content {
    margin-left: pxToRem(64);
    position: absolute;
    background: $oslo-gray;
    height: 100%;
    width: 100%;
  }

  &__menuItem {
    width: pxToRem(200);
    border: 1px solid $hit-gray;
    margin: 0 auto;
  }

  &__input-group {
    width: 30%;
  }
}

/* Custom style for storybook of component: Button */
.storybook__btn {
  &-area {
    .btn {
      margin: pxToRem(10);
    }
  }
}

/* Custom class for common component: indicator */
.indicator-custom {
  display: flex;
  justify-content: center;
}

.storybook__indicator-description {
  z-index: 20;
  background: $white;
  position: absolute;
  width: 100%;
  padding-bottom: pxToRem(20);
}

/* Custom style class for common component: SwitchButton */
.switch-button--custom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #f3f3f3;
  width: pxToRem(250);
  text-align: center;
  margin: pxToRem(10);
}

/* Custom style for common component: Modal */
.story__modal {
  &-btn {
    align-self: flex-end;
  }

  &-flexbox {
    .btn {
      margin-top: pxToRem(20);
    }
  }

  &--custom-size {
    width: pxToRem(600);
    height: pxToRem(250);
  }
}

.storybook__modal {
  width: 500px;
  margin: 0 auto;
}

/* Custom style for common-component: Form */
.story__form-login {
  background: $geyser;

  &__head {
    background: $hit-gray;
    height: pxToRem(70);
    text-align: center;
  }

  &__img {
    width: pxToRem(50);
    height: pxToRem(50);
    border-radius: 50%;
    margin-top: pxToRem(40);
  }

  &__body-wrapper {
    padding: pxToRem(20);
  }

  &__textbox {
    margin: pxToRem(20) 0;
    width: 100%;
    box-sizing: border-box;
  }
}

/* Custom style for common-component: Image */
.storybook__img {
  @include boxShadow(0 0 pxToRem(5) pxToRem(2) $hit-gray);
  border-radius: pxToRem(34);
  margin: pxToRem(10);
}

.storybook__icon {
  margin: auto;
  background: $dodger-blue;

  &-wrapper {
    @include flexContainer(space-around, center, row);
    align-items: center;
  }
}

.img--active {
  &.storybook__icon--active {
    background: $liat-red;
  }
}
