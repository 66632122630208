.form {
  &__auto-complete,
  &__auto-complete__content,
  &__auto-complete-wrapper {
    @extend %display-flex;
    align-items: center;
  }

  &__auto-complete-wrapper {
    width: calc(100% - 16px);
    flex-wrap: wrap;
  }

  &__auto-complete {
    border: 1px solid $heather;
    padding: 0 8px;
    min-height: 23px;
    margin: 2px;

    &__selected-options {
      @extend %display-flex;
      max-width: 100%;
      flex-wrap: wrap;
    }

    &:hover {
      border-color: $stone-green;
    }

    &:hover,
    &__input {
      &:focus {
        outline: none;
      }
    }

    &__input {
      width: 100%;
      min-width: 100px;
      border: none;
      align-self: flex-end;
    }

    &__text {
      margin: 0;
    }

    &__input,
    &__text,
    &__content {
      @extend .font-light;
      font-size: $font-size-tiny;
      color: $tundora;
    }

    &__content {
      @extend %display-flex;
      justify-content: space-between;
      align-items: center;
      min-width: 50px;
      background-color: $mercury;
      margin: 2px;
      padding: 2px 4px 0;

      p {
        max-width: 100%;
        line-height: $font-size-tiny;
      }
    }

    &--disabled {
      background-color: $concrete;
    }
  }
}
