.page-content {
  &__plan-wrapper {
    padding: 0 20px;

    .form__textbox {
      &::placeholder {
        text-align: left;
      }
    }

    .form__input-group--wrapper {
      margin-bottom: 0;
    }

    .page-content__form__header {
      @extend .font-light;
      font-size: $font-size-tiny;
      margin-top: 20px;

      a {
        color: $black;
      }
    }

    .master-class__cell__input {
      padding-top: 12px;
    }

    .master-class--view-only {
      min-width: 90px;
    }

    .master-class__group-button {
      display: table-cell;
    }
  }
}

.plan-wrapper__view-only {
  table {
    th:first-child,
    td:first-child {
      min-width: 80px;
      padding-left: 5px;
    }

    th,
    td {
      min-width: $table-column-width;
      max-width: $table-column-width;
    }
  }
}

.collection-wrapper__view-only {
  table {
    th:first-child,
    td:first-child {
      min-width: 250px;
    }
  }
}
