.pagination {
  margin-top: 30px;
  text-align: center;

  li {
    a {
      font-family: $base;
      font-size: $font-size-sm;
    }

    &.active {
      background-color: $stone-green;
    }
  }

  &__custom-list {
    a {
      cursor: default;
    }
  }
}
