.page-content {
  &__details {
    &__main-title {
      display: flex;

      i {
        line-height: 18px;
        cursor: pointer;
      }
    }
  }
}

.page-content__form {
  &__group-btn {
    align-items: center;

    &,
    .export-link {
      display: flex;
    }

    .export-link {
      cursor: pointer;
      margin-left: 20px;
      color: $stone-green;
      align-items: center;
      text-transform: uppercase;
      border: 1px solid $stone-green;
      padding: 10px;

      i {
        margin-right: 5px;
      }

      &:hover {
        opacity: 0.9;
      }

      &--disabled {
        color: $hit-gray;
        border-color: $hit-gray;
      }
    }
  }

  .workout {
    &__wrapper {
      @extend %display-flex;
      justify-content: space-between;
    }

    &__image-browse {
      width: 307px;
      padding-top: 57px;
    }

    &__content {
      width: 30.2%;
      // margin-top: 47px;

      .form__auto-complete {
        margin-top: 6px;
      }
    }

    &__form {
      margin-bottom: 20px;

      &--name {
        @extend .font-bold;
        height: 34px;
        font-size: $font-size-sm;

        &::placeholder {
          @extend .font-light;
        }
      }
    }

    &__back-link {
      @extend .font-light;
      font-size: $font-size-tiny;
      color: $black;
    }
  }

  .master-class__wrapper {
    table {
      display: block;
    }
  }

  .master-class__asset-wrapper {
    table {
      tr {
        th:first-child {
          width: 135px;
        }

        th {
          width: $table-column-width;
        }
      }
    }
  }
}

.export-workout-popup {
  &.popup {
    width: 500px;

    .popup-body {
      text-align: left;
      max-height: 450px;
      overflow: scroll;

      .btn-copy {
        @extend .font-light;
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-transform: capitalize;
        padding: 0;
        font-size: $font-size-tiny;

        &,
        i {
          color: $black;
        }

        i {
          margin-right: 5px;
        }
      }

      // .content-list {
      //   li {
      //     margin-bottom: 5px;
      //   }
      // }

      .content-desc {
        font-size: $font-size-tiny;
        line-height: $font-size-sm;

        &__highlight {
          font-weight: bold;
        }

        &__emphasic {
          color: $dusty-gray;
        }

        &__songs {
          list-style: circle;
        }

        &__track,
        &__presenter {
          margin-bottom: 20px;
        }

        &__highlight,
        p {
          margin: 0;
        }
      }
    }
  }
}
