/* Base variables */
$z-index-xs: 1;
$z-index-base: 5;
$z-index-max: 10;

$opacity-base: 0.65;

$transition-delay-base: 0.4s;

$border-radius-sm: pxToRem(3);
$border-radius-base: pxToRem(4);
$border-radius-md: pxToRem(5);

/* Variable for common-component: Indicator */
$indicator-size-sm: 16px;
$indicator-size-md: 30px;
$indicator-size-lg: 60px;

$indicator-border-size-sm: 3px;
$indicator-border-size-md: 6px;
$indicator-border-size-lg: 18px;

$indicator-backdrop-zindex: $z-index-max;

/* Variables for common-component: SwitchToggle */
$switch-button-width-sm: 60px;
$switch-button-height-sm: 26px;

$switch-button-width-md: 80px;
$switch-button-height-md: 34px;

$switch-button-width-lg: 100px;
$switch-button-height-lg: 40px;

$switch-button-slider-size-sm: 18px;
$switch-button-slider-size-md: 26px;
$switch-button-slider-size-lg: 32px;

$switch-button-slider-translate-sm: 34px;
$switch-button-slider-translate-md: 46px;
$switch-button-slider-translate-lg: 60px;

$switch-button-border-radius: 34px;

$switch-button-transition-delay: transition-delay-base;
$switch-button-opacity: $opacity-base;

/* Variable for common-component: Modal */
$modal-dialog-zindex: $z-index-max;
$modal-zindex: $z-index-base;
$modal-radius: $border-radius-base;

$modal-dialog-size-sm: pxToRem(350);
$modal-dialog-size-md: pxToRem(408);
$modal-dialog-size-lg: pxToRem(600);

/* Variable for common-component: Button*/
$button-radius: pxToRem(2);
$button-height: pxToRem(42);

$button-padding: pxToRem(12) pxToRem(25);
$button-padding-sm: pxToRem(12) pxToRem(16);
$button-padding-lg: pxToRem(12) pxToRem(32);

$button-height-sm: 23px;
$button-width: 185px;

/* Variable for common-component: Form */
$form-border-radius: $border-radius-base;

$form-size-sm: 350px;
$form-size-md: 500px;
$form-size-lg: 800px;

/* Variable for common-component: BrowseImage */
$browse-img-remove-bg-color: $black;
$browse-img-remove-color: $white;

$browse-img-disabled-opacity: $opacity-base;
$browse-img-radius: $border-radius-base;

/* Variable for common-component: Tooltip */
$tooltip-zindex: $z-index-max;

/* Variable for common-component: Image */
$img-height-sm: 150px;
$img-width-sm: 200px;

$img-height-md: 200px;
$img-width-md: 280px;

$img-width-lg: 550px;
$img-height-lg: 300px;

$icon-sm: 20px;
$icon-md: 30px;
$icon-lg: 40px;

$icon-padding-sm: 5px;
$icon-padding-md: 7px;
$icon-padding-lg: 10px;

$img-disabled-opacity: $opacity-base;

/* Variable for common-component: Input */
$input-height-sm: 23px;

$input-color-width: 170px;

/* Variable for common-component: Select */
$select-width: 185px;

/* Variable for common-component: CheckBox */
$checkbox-height-sm: 13px;
$checkbox-width-sm: 13px;

/* Variable for common-component: TextArea */
$textarea-height-md: 116px;

/* Variable for width component combine */
$combine-form-width: 375px;
$combine-list-width: 805px;

$table-column-width: 150px;
