$menu-left-distance: 30px;
$menu-right-distance: 18px;
$sub-menu-distance: 15px;

.aside {
  background-color: $main-black;
  margin-top: 0;
  position: relative;

  &__btn-toggle {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 30px;
    top: 30px;
    right: -10px;
    z-index: $z-index-max;
    box-shadow: 1px 1px 4px 1px rgba($color: #888, $alpha: 0.3);
    border: none;
    text-align: center;

    &:focus {
      outline: none;
    }
  }

  &__main-logo {
    text-align: left;
    display: block;
    padding-left: $menu-left-distance;
    margin-top: 40px;
    margin-bottom: 85px;

    img {
      height: $sub-menu-distance;
    }
  }

  &__group,
  &__item__link {
    font-size: $font-size-sm;
  }

  &__group {
    @extend .font-secondary;
    color: $group-menu-item;
    text-transform: uppercase;
    text-align: left;
    padding-left: $menu-left-distance;
    padding-right: $menu-right-distance;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &__group-container {
    // position: fixed;
    overflow-x: hidden;
    height: 80vh;
  }

  h4,
  .user-role {
    @extend .font-trade;
    text-transform: uppercase;
    text-align: center;
    color: $white;
    padding: 0 15px;
  }

  h4 {
    font-size: 22px;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .user-role {
    font-size: $font-size-tiny;
    margin-top: 0;
  }

  .aside-item--workout {
    background-color: $main-black;
  }

  &__item {
    text-transform: none;

    &:first-child {
      margin-top: 10px;
    }

    &__link {
      @extend .font-primary;
      width: 100%;
      justify-content: space-between;
      color: $side-bar-link;
      padding: 11px $menu-right-distance;
      margin-bottom: 2px;

      &--active {
        color: $white;
      }

      &,
      &__left {
        display: flex;
        align-items: center;
      }

      &__left {
        justify-content: flex-start;
      }

      &__icon {
        margin-right: 5px;
        width: 18px;
        justify-content: flex-start;

        img {
          width: auto;
        }
      }
    }

    &--active,
    &:hover {
      border-radius: $border-radius-sm;
    }

    &:hover {
      background-color: $dusty-gray;
    }

    &--active {
      &,
      &:hover {
        background-color: $stone-green;
      }
    }

    // Sub menu: list products for workout
    ul {
      padding-left: $sub-menu-distance;
    }

    &--has-sub-menu,
    &--disabled {
      &,
      &:hover {
        background-color: transparent;
      }
    }

    &--has-sub-menu {
      .aside__item {
        margin-top: 0;
      }

      ul li a {
        padding-left: $menu-right-distance;
      }
    }

    &--disabled {
      ul {
        margin-bottom: $menu-left-distance;
      }

      a {
        color: $side-bar-menu;
      }
    }
  }
}

.sign-out {
  @extend .font-light;
  text-decoration: underline;
  position: absolute;
  bottom: 10px;
  padding-left: $menu-left-distance;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: $white;
  font-size: $font-size-tiny;
  outline: none;
  z-index: $z-index-xs;
}
