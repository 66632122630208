%hight-light-text {
  @extend .font-trade;
  font-size: $font-size-xs;
  text-transform: uppercase;
}

.image-browse {
  &__header {
    justify-content: space-between;
    align-items: center;
  }

  &__header,
  &__content,
  &__upload-group {
    display: flex;
    align-items: center;
  }

  &__header {
    &__title {
      @extend %hight-light-text;
    }

    &--end {
      justify-content: flex-end;

      .btn-clear {
        margin-right: 0 !important;
      }
    }
  }

  &__upload {
    @extend .font-light;
    font-size: $font-size-tiny;
    background-color: $stone-green;
    color: $white;
    height: 23px;
    width: 53px;
    text-align: center;
    line-height: 25px;
    margin-bottom: 5px;
    cursor: pointer;

    input[type='file'] {
      display: none;
    }

    &--lg {
      width: auto;
      padding: 0 10px;
      margin-right: 10px;
    }

    &--disabled {
      pointer-events: none;
      background-color: $alto;
      box-shadow: none;
      color: $silver-chalice;
      cursor: default;
    }
  }

  &__content {
    position: relative;

    @extend %hight-light-text;
    font-size: $font-size-sm;
    width: 100%;
    height: 200px;
    background-color: $lightSilver;
    justify-content: center;
    color: $white;

    &--error {
      text-align: center;
      color: $liat-red;
    }
  }

  .btn-clear {
    @extend .font-light;
    background-color: $liat-red;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: $font-size-tiny;
    line-height: 18px;
    color: $white;

    &:focus {
      outline: none;
    }
  }
}
