.assets-wrapper {
  thead tr td .btn-action--small {
    width: auto;
  }

  // .master-class__cell__input .form__textbox {
  //   width: 100%;
  // }

  tbody tr td:not(:first-child) {
    width: auto;
  }

  .product-form__attribute-url {
    margin-top: 0;
  }
}
